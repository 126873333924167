.App {
  text-align: center;
}
body {
  
  background-color: rgb(202, 239, 227);

}
.gradient__text {
  
  font-family: 'Playfair Display';
  text-align: center;
  background-image: linear-gradient(45deg, rgb(44, 132, 44), rgb(160, 211, 160));
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: bold;
  display: inline; /* Ensures the background-clip applies to the text, not the entire block */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


@media screen and (min-width: 768px) {
  /* larger screen sizes */
  .gradient__text {
    font-size: 70px; /* larger font size */
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  /* medium-sized screens */
  .gradient__text {
    font-size: 70px; /* smaller font size */
  }
}