.contact-form-container {
    padding: 10px 20px;
    max-width: 800px;
    margin: 40px auto;
    background-color: #dffcfc; /* Slightly off-white background from .services */
    padding: 10px 20px; /* Padding from .services */
    border-radius: 15px; /* Rounded corners from .services */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08); /* Shadow from .services */
    
}

.contact-form-container h2 {
    margin-bottom: 30px;
    font-size: 2.5em;
    color: #2A5D67;
    letter-spacing: 2px;
    font-weight: bold;
    display: inline-block;
    padding-bottom: 10px;
    border-bottom: 2px solid #2A5D67;
}

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #dffcfc;
    padding: 10px 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    max-width: 800px;
    margin: 40px auto;
}

.contact-form-container label {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #2A5D67;
    letter-spacing: 2px;
    font-weight: bold;
}

.contact select,
.contact input[type="date"],
.contact input[type="time"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
    color: #2A5D67;
    background-color: white; /* Ensures the background color is white */
}

.contactButton {
    width: 20%; /* Adjusted to full width */
    padding: 10px;
    background-color: #4994a3;
    color: #dffcfc;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    letter-spacing: 1px;
    font-weight: bold;
    margin-top: 20px; /* Added margin at the top */
}

.contact button:hover {
    background-color: #1c3c40;
}

/* New container for Service select field to center it */
.serviceContainer {
    text-align: center;
    margin-bottom: 20px; /* Adds space between this section and the next */
}

.serviceContainer label,
.serviceContainer select {
    display: block; /* Makes sure the label and select are on separate lines */
    margin: 0 auto; /* Centers the block elements */
    width: fit-content; /* Makes the width of the element just enough to fit its content */
}

.optionsContainer select,
.optionsContainer select:focus,
.optionsContainer select:hover,
.serviceContainer select {
    color: black !important; /* Ensures text color is black */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    -webkit-appearance: none; /* Disables native appearance on iOS */
    -moz-appearance: none; /* Disables native appearance on Firefox */
    appearance: none; /* Disables native appearance on modern browsers */
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-color: white; /* Ensures the background color is white */
}

/* Specific rules for options in select dropdowns to ensure text color */
.optionsContainer select option,
.serviceContainer select option {
    color: black !important; /* Ensures option text color is black */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .contact-form-container {
        padding: 10px 20px; /* Maintain padding on smaller screens */
    }
    .contactButton {
        width: 20%; /* Full width on smaller screens */
        margin: 20px 0; /* Adjusted for spacing */
    }

    .serviceContainer select {
        width: 100%; /* Full width for the select box on smaller screens */
    }

    .contact .date-time-group {
        flex-direction: column;
    }

    .contact .date-time-group label {
        width: 100%;
    }
}
