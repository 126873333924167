.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    background-color: rgb(202, 239, 227);
    z-index: 4;
    position: relative;
}

.navbar__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.navbar__logo {
    width: 210px;
    height: 150px;   
}

.navbar__links {
    display: flex;
    gap: 20px;
}

.navbar__links a {
    color: rgb(34, 106, 100);
    text-decoration: none; 
    font-weight: bold;
    font-size: 1.1rem;
    transition: transform 0.3s ease; /* Add transition for transform */
}

.navbar__links a:hover {
    transform: scale(1.1); /* Grow the link slightly on hover */
}


.navbar__hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    position: relative;
}

.navbar__hamburger:hover .navbar__hamburger-icon {
    background-color: #b6dad9; 
    transition: background-color 0.3s ease;
}

.navbar__hamburger::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35px; /* Adjust as necessary for the size of the circle */
    height: 35px;
    background-color: rgba(255, 255, 255, 0.475); /* Translucent white, adjust rgba values as needed */
    border-radius: 50%; /* Makes it a circle */
    transform: translate(-50%, -50%) scale(0); /* Center it and initially scale down to 0 */
    transition: transform 0.3s ease, opacity 0.3s ease; /* Transition effect */
    z-index: -1; /* Place it behind the hamburger icon */
}

.navbar__hamburger:hover::before {
    transform: translate(-50%, -50%) scale(1); /* Scale it to its original size on hover */
    opacity: 1; /* Make it fully visible on hover */
}


.navbar__hamburger span {
    background-color: rgb(21, 128, 4);
    height: 2px;
    width: 25px;
    display: block;
    margin-bottom: 4px;
}


/* Responsiveness */
@media (max-width: 768px) {
    .navbar__links {
        display: none;
        flex-direction: column;
        align-items: center;
        position: absolute;
        background-color: #62f4de;
        top: 60px;
        right: 0;
        width: 20%;
        gap: 20px;
        border-radius: 5px;
        padding: 1rem;
        z-index: 1000; 
    }

    .navbar__links--active {
        display: flex;
    }

    .navbar__hamburger {
        display: flex;
    }

    .navbar__logo {
        width: 150px;
        height: 112.5px; 
    }
}

@media (max-width: 480px) {
    .navbar__logo {
        width: 150px;
        height: 112.5px; 
    }
}

.navbar__info {
    background-color: rgb(75, 171, 128);
    color: white;
    padding: 23px 20px;  /* Increase vertical padding for a "thicker" appearance */
    text-align: right;
    font-size: 18px;
    width: calc(100% - 200px);  /* Adjust width */
    margin-left: 160px;  /* Increase left margin */
    font-size: 16px;
}
/* Existing media queries... */


@media (max-width: 768px) {
    .navbar__info {
        font-size: 15px;
        width: calc(100% - 120px); /* Adjust to account for padding */
        margin-left: 80px; /* Reduce the left margin */
    }
}

@media (max-width: 580px) {
    .navbar__info {
        font-size: 13px;
        width: calc(100% - 60px); /* Adjust further for smaller screens */
        margin-left: 20px; /* Further reduce the left margin */
    }
}
