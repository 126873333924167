.gpt3__header {
    display: flex;
    flex-direction: row;
    padding-top: 4rem;
    background-color: antiquewhite;
  }
  
  .gpt3__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 10rem;
    
  }
  

  .gpt3__header-content h1 {
    
    font-weight: 800;
    font-size: 72px;
    text-align: center;
    letter-spacing: -0.04em;
    padding: 0 20px;
    animation: slideInFromLeft 1s ease-out forwards;
  }
  
  
  
  .gpt3__header-content__input {
    width: 90%;
    margin: 2rem 0 1rem;
  
    display: flex;
    flex-direction: row;
  }
  
  
  
  

  
  .gpt3__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .gpt3__header-image img {
    width: 90%;
    height: 90%;
    border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%;
    border-top-left-radius: 20%; 
    border-top-right-radius: 20%; 
    opacity: 0; /* Initial state */
    animation: fadeIn 2s ease-out 0s forwards; /* No delay */
}

  @media screen and (min-width: 1050px) {
    .gpt3__header-image img {
      width: 80%;
      height: 80%;

    }
  }
  @media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }
  
    .gpt3__header-content {
        margin: 0 0 3rem;
    }
    .gpt3__header-content h1 {
      text-align: center;
      font-size: 58px;
      
    }
    .gpt3__header-image img {
      width: 60%;
      height: 60%;

    }
  }
  @media screen and (max-width: 750px) {
    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
        text-align: center;
    }
    .gpt3__header-image img {
      width: 60%;
      height: 60%;

    }
  
  }
  @media screen and (max-width: 650px) {
    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
        text-align: center;
    }
  
    .gpt3__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .gpt3__header-image img {
      width: 60%;
      height: 60%;

    }
    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
  }
  
  @media screen and (max-width: 490px) {
    .gpt3__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }
  
    .gpt3__header-content p {
        font-size: 14px;
        line-height: 24px;
    }
  
    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
  }

  @media screen and (max-width: 768px) {
    .box-shadow-text {
      margin-right: 10px;
      text-align: center;
    }
    .gpt3__header-image img {
      width: 70%;
      height: 70%;

    }
    .gpt3__header-content h1 {
      font-size: 48px;
      align-items: center;
    }
  }
  
  @media screen and (max-width: 480px) {
    .box-shadow-text {
      margin-right: 10rem;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
    }
  } 

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: .5;
    }
    to {
      opacity: 1;
    }
  }
  