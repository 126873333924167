.servicesContainer {
    padding: 0px 20px;
}

.services {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #dffcfc;  /* Slightly off-white to give it a bit more warmth */
    padding: 10px 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08); /* Subtle shadow for depth */
    max-width: 800px;
    margin: 40px auto;

}

.services h1 {
    margin-bottom: 30px;
    font-size: 2.5em;
    color: #2A5D67;  /* Dark teal, but adjust according to your design's color scheme */
   
    letter-spacing: 2px;
    font-weight: bold;
    
    display: inline-block; /* For the underline effect to fit the text width */
    padding-bottom: 10px; /* Space between text and underline */
}

.services p {
    margin-bottom: 30px;
    font-size: 1.5em;
    color: #2A5D67;  /* Dark teal, but adjust according to your design's color scheme */
   
    letter-spacing: 2px;
    font-weight: bold;
    
    display: inline-block; /* For the underline effect to fit the text width */
    padding-bottom: 10px; /* Space between text and underline */
}

.serviceItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid #2A5D67;
    padding: 10px 0;
}

.serviceItem:last-child {
    border-bottom: none; /* Remove the bottom border for the last item */
}
