.learn-more-btn {
    background: linear-gradient(to right, #2a5955, #1e7f9c);
    color: rgb(249, 246, 246);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .learn-more-btn:hover {
    opacity: 0.8;
  }
  .Form1 {
    background-color: #9ad3a580;
  }
  .form {
    
    color: rgb(28, 64, 84);

  }

  .getitgirl {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 7px solid rgb(141, 137, 137);
    padding: 70px;
    margin-top: 50px;
    width: 70%;
  margin-left: auto;
  margin-right: auto;

  }
  
  .getitgirl img {
    max-width: 70%;
    max-height: 70%;
  }

.new_text {
  color: rgb(110, 179, 126);
  font-family: 'Abril Fatface', cursive;
  font-size: 55px;
  margin-top: -20px;
  font-weight: bold;
  
}

html {
  scroll-behavior: smooth;
}
